import React from 'react'
import { FaInstagram } from 'react-icons/fa';
import { IoMdHeart } from "react-icons/io";

function Footer() {
  return (
    <div className="h-auto z-30">
      <div className="grid grid-cols-1 lg:grid-cols-5 gap-y-4 mx-auto container pt-40">  
        <nav className="w-56 mx-auto lg:mx-0">
          <a href="#">
            <img className="h-[110px]" src="./img/logo.png" alt="logo" />
            <div className="
              mt-6 border-4 p-2 rounded-xl items-center  border-black justify-center text-center bg-white shadow-lg">
              <div className="w-full text-3xl font-black">
                06:00-19:00
              </div>
              <div className="w-full text-sm font-bold uppercase tracking-wide">
                AÇIK
              </div>
            </div>
          </a>
          <p className="mt-7 text-gray-700 ">Doğanın Kalbinde Eşsiz Bir Deneyim <span className=" font-black"><br></br>ATİYE ATLI YAŞAM</span></p>
          <div className="flex gap-x-2 mt-7">  
            <a href='https://www.instagram.com/atiyeatliyasam'>
              <div className="bg-gray-300 h-[32px] w-[32px] rounded-full flex items-center justify-center hover:bg-blue-900 hover:text-white text-gray-400">
                <FaInstagram size={28} />
              </div>
            </a>
          </div>
        </nav>

        <nav className="hidden lg:flex flex-col">
          <h1 className="font-bold">Hizmetlerimiz</h1>
          <ul className="font-light space-y-4 mt-7">
            <li>Binicilik Dersleri</li>
            <li>At Pansiyonu</li>
            <li>Kafe ve Restoran</li>
            <li>Etkinlikler</li>
            <li>Okçuluk ve Atlı Okçuluk</li>
            <li>Etkinlik Alanları</li>
            <li>Film Kiralama</li>
          </ul>
        </nav>

        <nav className="hidden lg:flex flex-col">
          <h1 className="font-bold">Hakkımızda</h1>
          <ul className="font-light space-y-4 mt-7">
            <li>Biz Kimiz?</li>
            <li>Vizyon ve Misyon</li>
            <li>Sıkça Sorulan Sorular</li>
            <li>Referanslarımız</li>
            <li>Kariyer</li>
            <li>Çocuklar için Çiftlik</li>
            <li>Hizmet Detayları</li>
          </ul>
        </nav>

        <nav className="hidden lg:flex flex-col">
          <h1 className="font-bold">Şirket</h1>
          <ul className="font-light space-y-4 mt-7">
            <li>Şirket Profili</li>
            <li>Vizyon ve Misyon</li>
            <li>Kariyer Fırsatları</li>
            <li>Sertifikalar</li>
            <li>Referanslar</li>
            <li>Markalar</li>
            <li>Kalite</li>
          </ul>
        </nav>

        <nav className="hidden lg:flex flex-col">
          <h1 className="font-bold">Diğer</h1>
          <ul className="font-light space-y-4 mt-7">
            <li>Sıkça Sorulan Sorular</li>
            <li>İletişim</li>
            <li>Gizlilik Politikası</li>
            <li>Kullanım Şartları</li>
            <li>Kurslar</li>
            <li>Dersler</li>
            <li>Teklif Al</li>
          </ul>
        </nav>
      </div>

      <a href='https://345.com.tr'>
        <div className="flex container mx-auto justify-center text-ucdortbes gap-x-1 mt-20 pb-5 items-center">
          <img className="h-[33px]" src="./img/345logo.png" alt="logo" />
          <h1 className="font-black text-black">made with</h1>
          <IoMdHeart size={26}/>
        </div>
      </a>
    </div>
  );
}

export default Footer;