import React from 'react'

const HomeAbout = () => {
  return (
    <div className="mx-auto container grid md:grid-cols-2 grid-cols-1 items-center mb-24">
      <div className="py-12 px-6">
          <h2 className="text-xl md:text-2xl font-semibold text-orange">Hakkımızda</h2>
          <p className="mt-4 text-blue-900 text-3xl md:text-4xl font-bold">
          Doğayla İç İçe,<br /> Atların Büyülü Dünyasında
          </p>
      </div>
      <div className="md:mt-6 mt-1 text-gray-600 px-4 md:px-0">
        <p>Atiye Atlı Yaşam olarak, doğanın huzurunu ve atların büyülü dünyasını bir araya getiriyoruz. Binicilik tutkunları için özel olarak tasarlanmış alanlarımızda profesyonel eğitimlerden keyifli etkinliklere kadar geniş bir yelpazede hizmet sunuyoruz.</p>
        <p className="mt-4">At pansiyonu, okçuluk, atlı okçuluk ve film kiralama gibi hizmetlerle sadece bir çiftlik değil, aynı zamanda unutulmaz anılar biriktirebileceğiniz bir yaşam alanıyız.</p>
      </div>
    </div>
  )
}

export default HomeAbout;