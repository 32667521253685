import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Testimonials = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    };

    const testimonials = [
        {
            name: "Ali Yılmaz",
            feedback: "Atiye Atlı Yaşam, huzur ve doğayı bir araya getiriyor. Çocuklarımız binicilik derslerinden çok keyif aldı!",
            image: "/img/at1.webp"
        },
        {
            name: "Merve Demir",
            feedback: "Doğanın ortasında, harika bir deneyim! At pansiyonu hizmetleri gerçekten profesyonel.",
            image: "/img/at2.webp"
        },
        {
            name: "Ahmet Kaya",
            feedback: "Atlar ve doğa ile iç içe olmak beni çok mutlu etti. Herkes için tavsiye ederim.",
            image: "/img/at3.webp"
        },
        {
            name: "Ayşe Şahin",
            feedback: "Çocuklarımın doğum gününü burada kutladık ve mükemmel bir deneyim yaşadık. Hizmet kalitesi harika!",
            image: "/img/at4.webp"
        },
        {
            name: "Mehmet Çelik",
            feedback: "Atiye Atlı Yaşam, doğa ile bağ kurmak için harika bir yer. Atlı okçuluk denemek müthişti.",
            image: "/img/at5.webp"
        }
    ];

    return (
        <div className="mt-24 py-16">
            <div className="container mx-auto px-4">
                <h2 className="text-3xl font-bold text-center mb-10">Misafirlerimizin Görüşleri</h2>
                <Slider {...settings}>
                    {testimonials.map((testimonial, index) => (
                        <div key={index} className="p-4">
                            <div className="bg-white rounded-lg  p-6">
                                <img
                                    src={testimonial.image}
                                    alt={testimonial.name}
                                    className="w-16 h-16 rounded-full mx-auto mb-4 object-cover"
                                />
                                <h3 className="text-lg font-semibold text-center">{testimonial.name}</h3>
                                <p className="text-gray-600 text-center mt-3">{testimonial.feedback}</p>
                            </div>
                        </div>
                    ))}
                </Slider>
            </div>
        </div>
    );
};

export default Testimonials;