import React from 'react'
import { RiChatSmile2Line, RiCrosshair2Fill, RiHazeLine, RiMedalLine } from "react-icons/ri";
import { AiOutlineSmile } from "react-icons/ai";
import { GiTreeBranch } from "react-icons/gi";
import { MdHealthAndSafety } from "react-icons/md";



const Info = () => {
  return (
    <div className="container mx-auto mt-24 px-4">
        <h1 className="text-3xl font-bold text-center">Hakkımızda</h1>
        <h2 className="text-xl font-normal text-center mt-4">
        Atiye Atlı Yaşam, doğayla iç içe huzuru, sporları ve geleneksel değerleri bir araya getiren eşsiz bir yaşam alanıdır. Uzman kadromuz, sağlıklı atlarımız ve geniş hizmet yelpazemizle, her yaştan doğa tutkunlarına unutulmaz bir deneyim sunmayı amaçlıyoruz. Misafirlerimize, doğanın içinde kaliteli ve güvenilir bir hizmet sunarak benzersiz anılar biriktirme fırsatı tanıyoruz.
        </h2>
        
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-12">
            {[
                { icon: AiOutlineSmile, title: "Misafir Memnuniyeti", description: "Misafirlerimizin ihtiyaçlarını tam olarak anlayarak, onlara özel deneyimler sunmayı hedefliyoruz. Güvenli, huzurlu ve keyifli bir ortamda kaliteli hizmet anlayışını benimseyerek, her ziyaretçimize unutulmaz anılar biriktirme fırsatı sağlıyoruz." },
                { icon: GiTreeBranch, title: "Doğa Dostu Yaklaşım", description: "Atiye Atlı Yaşam, sürdürülebilir bir gelecek için doğaya saygılı çözümlerle çalışır. Çiftliğimizde çevre dostu teknolojiler kullanılarak, doğal kaynakların korunması ve sürdürülebilirlik esas alınır. Her detay, çevreye duyarlı bir anlayışla tasarlanmıştır." },
                { icon: MdHealthAndSafety, title: "Sağlık ve Güvenlik", description: "Atlarımızın sağlığı ve güvenliği önceliğimizdir. Uzman ekibimiz, atlarımızın en iyi koşullarda yaşamasını sağlar ve düzenli bakımlarını gerçekleştirir. Misafirlerimiz için de güvenli bir ortam sunarak keyifli bir deneyim yaratıyoruz." },
                { icon: RiMedalLine, title: "Gelenek ve Modernlik", description: "Binicilik, okçuluk ve atlı okçuluk gibi geleneksel sporları modern konforla birleştirerek, ziyaretçilerimize eşsiz bir deneyim sunuyoruz. Hem spor hem de eğlenceyi bir arada yaşayabileceğiniz çiftliğimizde, geçmişin değerleriyle bugünün teknolojisini buluşturuyoruz." }
            ].map((item, index) => (
                <div className="flex flex-col items-center justify-center mt-14">
                    <div className="bg-orange-600 h-16 w-16 rounded-lg flex items-center justify-center">
                        <item.icon size={40} color='white' />
                    </div>
                    <h2 className="text-center mt-3 font-semibold">{item.title}</h2>
                    <p className="text-center w-9/12">{item.description}</p>
                </div>
            ))}
        </div>
    </div>
  )
}

export default Info