import React from 'react'

export default function Elevator() {
  return (
    <div
  className="bg-gray-900"
>
        <div className="mx-auto container flex flex-col md:flex-row justify-between p-5 md:p-20 mt-20 md:mt-80">
            <div className="text-white md:w-3/5 flex flex-col justify-center">
                <h1 className="font-bold text-xl md:text-2xl ">Atiye Atlı Yaşam, binicilik ve geleneksel sporları modern konforla buluşturan bir yaşam alanıdır. Eğitimli personelimiz, sağlıklı atlarımız ve geniş hizmet yelpazemizle her yaştan doğa ve at tutkunlarını ağırlıyoruz. Burada hem doğanın huzurunu bulacak hem de unutulmaz bir deneyim yaşayacaksınız.</h1>
                <ul className="mt-6 space-y-4">
                    <li className="font-thin"><span className="font-bold">Eğitim ve Uzmanlık:</span> Uzman eğitmenlerimiz ve deneyimli ekibimiz, binicilikten okçuluğa kadar her alanda en iyi eğitimi sunar. Hem yeni başlayanlara hem de profesyonellere uygun programlarla destek veriyoruz.</li>
                    <li className="font-thin"><span className="font-bold">Doğaya Saygılı Hizmetler:</span> Sürdürülebilirlik ilkemizle, doğayla uyum içinde çalışan bir çiftlik olarak hizmet veriyoruz. Her detayda çevreye duyarlı çözümler sunarak, hem misafirlerimize hem de doğaya değer katıyoruz.</li>
                    <li className="font-thin"><span className="font-bold">Geniş Hizmet Yelpazesi:</span> Binicilik derslerinden at pansiyonuna, atlı okçuluktan film kiralama hizmetlerine kadar herkes için bir şeyler sunuyoruz. Etkinlik alanlarımız ve kafe-restoranlarımızla özel anılar biriktirmenizi sağlıyoruz.</li>
                    <li className="font-thin"><span className="font-bold">Sağlıklı ve Mutlu Atlar:</span> Atlarımızın sağlığı ve mutluluğu bizim için önceliklidir. Özenle hazırlanan pansiyonumuzda atlarımız en iyi koşullarda bakılmaktadır.</li>
                    <li className="font-thin"><span className="font-bold">Misafir Memnuniyeti:</span> Her misafirimize özel bir deneyim sunuyoruz. Hizmetlerimizi, ihtiyaçlarınıza ve isteklerinize göre kişiselleştiriyoruz.</li>
                </ul>
            </div>

            <div className="text-white mt-6 md:mt-0">
            <img src="/img/at1.png" alt="Otis" className="w-full md:w-[550px] h-auto object-contain" />
            </div>

        </div>
    </div>
  )
}