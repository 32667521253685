import React from 'react'

const Product = () => {
  return (
    <div className="mx-auto mt-36 bg-oval bg-contain">
      <div className="mx-auto container grid grid-cols-1 md:grid-cols-2">
        <div className="flex flex-col justify-center p-4">
          <h1 className="text-3xl font-bold text-left">
          Hizmetlerimizin Arkasındayız
          </h1>
          <p className="text-xl font-normal text-left mt-4">
          Çiftliğimizde sağlanan her hizmet, yılların deneyimi ve doğaya olan sevgimizle şekilleniyor. At pansiyonumuzda atlarınızı güvenle misafir edebilir, binicilik derslerimizle yeni bir tutkuya adım atabilirsiniz. Hizmetlerimizin kalitesine güveniyor ve her adımda memnuniyetinizi garanti ediyoruz.
          </p>
        </div>
        <div className="flex flex-wrap justify-center items-center gap-4 p-4">
          <img src="/img/at8.webp" alt="Background" className="w-160  rounded-lg shadow-2xl" />
        </div>
      </div>
    </div>
  )
}

export default Product