import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Modal from './../components/PopUpModal';
import { motion, AnimatePresence } from "framer-motion";

function Header() {
  const location = useLocation();
  const [isModalOpen, setModalOpen] = useState(false);
  const [isMenuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (!event.target.closest('.menu-button, .menu-content')) {
        setMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);
    return () => document.removeEventListener('mousedown', handleOutsideClick);
  }, []);

  const burgerIconColor = location.pathname === '/' ? 'text-white' : 'text-black';

  return (
    <div className="relative w-full z-50">
      <div className="container mx-auto flex justify-between items-center h-[100px] px-4">
        <a href="/">
          <img className="h-[80px] md:h-[90px]" src={location.pathname === '/' ? './img/logoW.png' : './img/logo.png'} alt="logo" />
        </a>
        <ul className={`hidden md:flex space-x-6 items-center font-semibold ${location.pathname === '/' ? 'text-white' : 'text-black'}`}>
          <li><Link to="/">Anasayfa</Link></li>
          <li><Link to="/hakkimizda">Hakkımızda</Link></li>
          <li><Link to="/urunler">Hizmetler</Link></li>
          <li><Link to="/iletisim">İletişim</Link></li>
          <li className="cursor-pointer border-2 rounded-lg p-2" onClick={() => setModalOpen(true)}>DERS AL</li>
        </ul>
        <button onClick={toggleMenu} className={`text-3xl md:hidden menu-button ${burgerIconColor}`}>&#9776;</button>
      </div>
      <AnimatePresence>
        {isMenuOpen && (
          <motion.div
            className="absolute top-0 left-0 w-full h-screen bg-white z-40 flex flex-col items-center justify-center"
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -50 }}
            transition={{ duration: 0.3 }}
          >
            <button
              className="absolute top-4 right-4 text-3xl text-black"
              onClick={toggleMenu}
              aria-label="Kapat"
            >
              ✕
            </button>
            <ul className="text-2xl font-bold text-center space-y-6 mt-8">
              <motion.li initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 0.1 }}>
                <Link to="/" onClick={toggleMenu}>Ana Sayfa</Link>
              </motion.li>
              <motion.li initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 0.2 }}>
                <Link to="/hakkimizda" onClick={toggleMenu}>Hakkımızda</Link>
              </motion.li>
              <motion.li initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 0.3 }}>
                <Link to="/urunler" onClick={toggleMenu}>Hizmetler</Link>
              </motion.li>
              <motion.li initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 0.4 }}>
                <Link to="/iletisim" onClick={toggleMenu}>İletişim</Link>
              </motion.li>
              <motion.li 
  className="flex justify-center mt-4"
  initial={{ opacity: 0 }} 
  animate={{ opacity: 1 }} 
  transition={{ delay: 0.5 }}
>
  <Link to="/i" onClick={toggleMenu}>
    <img className="h-[90px]" src='./img/logo.png' alt="logo" />
  </Link>
</motion.li>
            </ul>
          </motion.div>
        )}
      </AnimatePresence>
      <Modal isOpen={isModalOpen} onClose={() => setModalOpen(false)} />
    </div>
  );
}

export default Header;