import React from 'react';
import { BiArea } from "react-icons/bi";

const Products = () => {
  // Veri statik olarak tanımlandı
  const data = [
    {
      id: 1,
      name: "Binicilik Dersleri",
      imageUrl: "/img/at1.webp",
      feature: "Profesyonel eğitmenlerle güvenli ve keyifli binicilik deneyimi.",
      camp: true,
    },
    {
      id: 2,
      name: "At Pansiyonu",
      imageUrl: "/img/at2.webp",
      feature: "Atlarınız için güvenli ve konforlu bir barınma alanı.",
      camp: false,
    },
    {
      id: 3,
      name: "Kafe ve Restoran",
      imageUrl: "/img/at3.webp",
      feature: "Doğayla iç içe leziz yemekler ve sıcak bir ortam.",
      camp: false,
    },
    {
      id: 4,
      name: "Etkinlik Alanları",
      imageUrl: "/img/at4.webp",
      feature: "Doğum günü, organizasyon ve özel etkinlikler için harika bir alan.",
      camp: true,
    },
    {
      id: 5,
      name: "Okçuluk ve Atlı Okçuluk",
      imageUrl: "/img/at5.webp",
      feature: "Geleneksel okçuluk deneyimini modern bir yaklaşımla sunuyoruz.",
      camp: false,
    },
    {
      id: 6,
      name: "Film Kiralama",
      imageUrl: "/img/at6.webp",
      feature: "Özel projeleriniz için çiftlikte film çekim alanları sunuyoruz.",
      camp: false,
    },
  ];

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 mx-auto container gap-y-16 mt-16 gap-x-4 px-4 sm:px-0">
      {data.map(product => (
        <div key={product.id} className="relative rounded-lg bg-black ease-in-out duration-300 hover:scale-105">
          {product.camp && (
            <div className="absolute top-2 left-2 right-0 text-center text-white bg-black bg-opacity-80 py-1 rounded-xl w-32 text-sm font-bold ">
              KAMPANYA
            </div>
          )}
          <img src={product.imageUrl} alt={product.name} className="h-[302px] w-full object-cover rounded-lg" />
          <h1 className="text-center font-bold text-2xl mt-3 text-white">{product.name}</h1>
          <div className="flex text-l items-center font-semibold justify-center gap-4 mt-3 p-8 text-white pb-4">
            <div className="flex justify-center mb-5 text-base font-thin text-center">{product.feature}</div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Products;