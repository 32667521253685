import React from 'react'

const Photo = () => {
  return (
    <div className="p-5">
      <div className="flex flex-wrap">
        <div className="w-full md:w-auto md:ml-[293px] mb-4">
          <img src="/img/at5.webp" alt="Background" className="w-full md:h-[460px] md:w-[380px] object-cover rounded-lg" />
        </div>
        <div className="w-full md:w-auto md:ml-[24px] mt-4 md:mt-[125px] mb-4">
          <img src="/img/at2.webp" alt="Background" className="w-full md:h-[240px] md:w-[360px] object-cover rounded-lg" />
        </div>
        <div className="w-full md:w-auto md:ml-[24px] mt-4 md:mt-[45px] mb-4">
          <img src="/img/at3.webp" alt="Background" className="w-full md:h-[320px] md:w-[440px] object-cover rounded-lg" />
        </div>
      </div>
      <div className="flex flex-wrap">
        <div className="w-full md:w-auto mb-4">
          <img src="/img/at4.webp" alt="Background" className="w-full md:h-[320px] md:w-[440px] mt-4 md:mt-[24px] object-cover rounded-lg" />
        </div>
        <div className="w-full md:w-auto md:ml-[24px] mt-4 md:mt-[24px] mb-4">
          <img src="/img/at1.webp" alt="Background" className="w-full md:h-[240px] md:w-[210px] object-cover rounded-lg" />
        </div>
        <div className="w-full md:w-auto md:ml-[24px] mt-4 md:mt-[-71px] mb-4">
          <img src="/img/at7.webp" alt="Background" className="w-full md:h-[420px] md:w-[600px] object-cover rounded-lg" />
        </div> 
        {/* galleryPIC.svg is not displayed on mobile */}
        <div className="hidden md:block md:ml-[24px] md:mt-[-71px]">
          <img src="/img/galleryPIC.svg" alt="Background" className="object-cover" />
        </div> 
      </div> 
    </div>
  )
}

export default Photo;